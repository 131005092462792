/* -----------------------------------------------------------------------------

  IMPORT GLOBAL NODE MODULES

----------------------------------------------------------------------------- */
// Import Bootstrap Framework
// import 'bootstrap';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/tooltip';
import 'bootstrap/js/dist/popover';
import 'bootstrap/js/dist/modal';


import Ajaxchimp from './jquery-ajaxchimp';

// /* -----------------------------------------------------------------------------
//
//   IMPORT CUSTOM MODULES
//
// ----------------------------------------------------------------------------- */
//
import Custom from './custom';
import Contact from './contact-form';
import News from './news';

// /* -----------------------------------------------------------------------------
//
//   ADD OBJECTS TO GLOBAL NAMESPACE
//
// ----------------------------------------------------------------------------- */

global.$ = global.jQuery = $;


// /* -----------------------------------------------------------------------------
//
//   EXECUTE FUNCTIONS
//
// ----------------------------------------------------------------------------- */

// $(document).foundation();
