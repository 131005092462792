$(function(){

    "use strict";

    var element = $('.newsitems');
    var url     = '/home.json';
    var limit   = parseInt(element.data('limit'));
    var offset  = limit;

    $('.next-items').on('click', function(e) {
        $('.loading').html('<div class="lds-ring"><div></div><div></div><div></div><div></div></div>');

        $.ajax({
            type: 'POST',
            dataType: 'json',
            url: url,
            data: {limit: limit, offset: offset, data: 'news'},

            // if the ajax call is successful ...
            success: function(response) {
                console.log(response);
                if(response.more === false) {
                    $('.next-items').hide();
                }

                $('.loading').empty();
                element.children().last().after(response.html);
                offset += limit;

                // init tooltip for loaded items
                $('[data-toggle="tooltip"]').tooltip();
                $('.news .rundbrief-item').hover(
                    function(){
                        $(this).find('.caption-hover').fadeIn(500); //.fadeIn(250)
                    },
                    function(){
                        $(this).find('.caption-hover').fadeOut(500); //.fadeOut(205)
                    }
                );
            }
        });


        // $.get(url, {limit: limit, offset: offset}, function(data) {
        //
        //   if(data.more === false) {
        //     $('.load-more').hide();
        //   }
        //
        //   element.children().last().after(data.html);
        //
        //   offset += limit;
        //
        // });

    });

    $.ajaxChimp.translations.de = {
        'submit': 'Senden...',
        0: '<i class="fas fa-check"></i> Wir haben Ihnen eine Bestätigungs-E-Mail geschickt.',
        1: '<i class="fas fa-exclamation-triangle"></i> Bitte füllen Sie alle Felder aus.',
        2: '<i class="fas fa-exclamation-triangle"></i> Eine E-Mail-Adresse muss ein @ enthalten.',
        3: '<i class="fas fa-exclamation-triangle"></i> Der Domain-Teil der E-Mail-Adresse ist ungültig (der Teil nach dem @).',
        4: '<i class="fas fa-exclamation-triangle"></i> Der Benutzername der E-Mail-Adresse ist ungültig (der Teil vor dem @).',
        5: '<i class="fas fa-exclamation-triangle"></i> Diese E-Mail-Adresse scheint gefälscht oder ungültig zu sein. Bitte geben Sie eine echte E-Mail-Adresse an!'
    };

    var cfg = {
        mailChimpURL: 'https://aktion-verzicht.us8.list-manage.com/subscribe/post?u=ff2bf1fd9bcd19a8b020bc5ee&amp;id=4e58b994e3'  // mailchimp url
    };

    $('#mc-form').ajaxChimp({
        language: 'de',
        url: cfg.mailChimpURL
    });


});
