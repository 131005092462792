$('#contact-form').on('submit', function(e) {
    e.preventDefault();

    // clear alerts
    $('.invalid-feedback').text('');
    $('.form-control').removeClass('is-invalid');

    // define some variables
    var form = $(this);
    var url  = form.attr('action') + '/home.json';
    var data = form.serialize();

    $.ajax({
        type: 'POST',
        dataType: 'json',
        url: url,
        data: data,

        // if the ajax call is successful ...
        success: function(response) {

            // check if the honeypot was filled out, if yes, redirect somewhere (your homepage, the same page)
            if(response.redirect === true) {
                return;
            }

            // in case form validation has errors
            if(response.errors) {

                // loop through errors array
                $.each(response.errors, function(key, message) {
                    // find the alert box for each input field
                    var alert = form.find('#' + key).next();
                    var element = form.find('#' + key);

                    // add the error message to the field
                    alert.text(message);
                    element.addClass('is-invalid');
                });

                $('html, body').animate({
                    scrollTop: $(".form-control.is-invalid:first").offset().top - 150
                }, 500);

                // workaround to fix locked scroll behavoir
                setTimeout(function(){
                    $("html, body").stop();
                }, 600);

            }

            // if registration was successful
            if(response.success) {
                var element = $('#message-container');

                // show success message and hide form
                element.addClass('alert alert-success');
                element.html(response.success);
                form.hide();

                $('html, body').animate({
                    scrollTop: element.offset().top - 100
                }, 500);

                // workaround to fix locked scroll behavoir
                setTimeout(function(){
                    $("html, body").stop();
                }, 600);
            }

            // if registration failed
            if(response.error) {
                var element = $('#message-container');

                // show error message
                element.addClass('alert alert-danger');
                element.html(response.error);
            }

        }
    });
});
