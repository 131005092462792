/* affix the navbar after scroll below header */
// $('#navbar').affix({
//       offset: {
//           top: 100
//        // top: $('#startseite').height()
//         //  top: $('#startseite').height()-$('#navbar').height()
//       }
// });


// $('body').scrollspy({ target: '#navbar' })
$(document).ready(function(){
  var scrollTop = 0;
  $(window).scroll(function(){
    scrollTop = $(window).scrollTop();
     $('.counter').html(scrollTop);

    if (scrollTop >= 100) {
      $('#navbar').addClass('scrolled-nav');
    } else if (scrollTop < 100) {
      $('#navbar').removeClass('scrolled-nav');
    }

  });

  // Open modal when url param is set
  var param = 'm';
  var url_string = window.location.href;
  var url = new URL(url_string);
  var paramValue = url.searchParams.get('m');
  if(url_string.indexOf('?' + param + '=') != -1)
    $('#'+paramValue).modal('show'); 

});


// Activate smoothscroll
$( function () {
  $('a[href^="#"] ').on('click', function(e) {


    var element = $(this).attr('href');

    $('html, body').animate({
        scrollTop: $(element).offset().top
    }, 500);

    // workaround to fix locked scroll behavoir
    setTimeout(function(){
        $("html, body").stop();
    }, 600);

    window.history.pushState({}, '', this.hash);
    e.preventDefault();
  });
});

// Close navbar on click
$(document).on('click','.navbar-collapse.show',function(e) {
    if( $(e.target).is('a') ) {
        $(this).collapse('hide');
    }
});

// Skrollr
//--------
//$(function() {
//    var windowWidth = $(window).width();
//    if(!(/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
//    skrollr.init({
//        forceHeight: false
//    });
//}});



// Rollover für mehr Infos
$(document).ready(function(){

    $("[rel='tooltip']").tooltip();

    $('.sammelstellen-single .sammelstellen-img, .news .rundbrief-item, #tx_projekte .project-item').hover(
        function(){
            $(this).find('.caption-hover').fadeIn(500); //.fadeIn(250)
        },
        function(){
            $(this).find('.caption-hover').fadeOut(500); //.fadeOut(205)
        }
    );
});

$('.pos-center, td').tooltip({
    selector: "[data-toggle=tooltip]"
});

$('td').popover({
    selector: "[data-toggle=popover]"
});


// Close modal if anchor link is clicked
$( function () {
  $('.modal-body a[href^="#"] ').on('click', function(e) {
    activeModal = this.closest('.modal');
    $('#'+activeModal.id).modal('hide');

  })

});